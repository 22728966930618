import { Pivot, PivotItem } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./AnalysisPanel.module.css";

import { SupportingContent } from "../SupportingContent";
import { ChatAppResponse } from "../../api";
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";

const RYZEREADER_STORAGE_URI = import.meta.env.VITE_RYZEREADER_STORAGE_URI;

interface Props {
    className: string;
    activeTab: AnalysisPanelTabs | undefined;
    onActiveTabChanged: (tab: AnalysisPanelTabs | undefined) => void;
    activeCitation: string | undefined;
    citationHeight: string;
    answer: ChatAppResponse;
}

const pivotItemDisabledStyle = { disabled: true, style: { color: "grey" } };

export const AnalysisPanel = ({ answer, activeTab, activeCitation, citationHeight, className, onActiveTabChanged }: Props) => {
    const isDisabledThoughtProcessTab: boolean = !answer.choices[0].context.thoughts;
    const isDisabledSupportingContentTab: boolean = !answer.choices[0].context.data_points.length;
    const isDisabledCitationTab: boolean = !activeCitation;
    const sanitizedThoughts = DOMPurify.sanitize(answer.choices[0].context.thoughts!);
    const extension = activeCitation?.split(".").pop();
    const isMsOffice = "docxxlsxpptx".indexOf(extension!) >= 0;
    const currentUrl = window.location.origin;
    const projectIndex: number = activeCitation?.indexOf('projectId=') ?? 0
    const fileIndex: number = activeCitation?.indexOf('file=') ?? 0
    const projectId= activeCitation?.substr(projectIndex+10,32)
    const fileId= activeCitation?.substr(fileIndex+5,72)
    const storageUrl2 = `${RYZEREADER_STORAGE_URI}/documents/${projectId}/${fileId}`
    const msOfficePreviewUrl = 'https://view.officeapps.live.com/op/embed.aspx?src='+ storageUrl2;

    return (
        <Pivot
            className={className}
            selectedKey={activeTab}
            onLinkClick={pivotItem => pivotItem && onActiveTabChanged(pivotItem.props.itemKey! as AnalysisPanelTabs)}
        >
            <PivotItem
                itemKey={AnalysisPanelTabs.ThoughtProcessTab}
                headerText="Thought process"
                headerButtonProps={isDisabledThoughtProcessTab ? pivotItemDisabledStyle : undefined}
            >   
                <div className={styles.thoughtProcess} dangerouslySetInnerHTML={{ __html: sanitizedThoughts }}></div>
            </PivotItem>
            <PivotItem
                itemKey={AnalysisPanelTabs.SupportingContentTab}
                headerText="Supporting content"
                headerButtonProps={isDisabledSupportingContentTab ? pivotItemDisabledStyle : undefined}
            >
                <SupportingContent supportingContent={answer.choices[0].context.data_points} />
            </PivotItem>
            <PivotItem
                itemKey={AnalysisPanelTabs.CitationTab}
                headerText="Citation"
                headerButtonProps={isDisabledCitationTab ? pivotItemDisabledStyle : undefined}
            >
                {isMsOffice && <iframe title="Citation" src={msOfficePreviewUrl} width="100%" height={citationHeight} />}
                {!isMsOffice && <iframe title="Citation" src={activeCitation} width="100%" height={citationHeight} />}
            </PivotItem>
        </Pivot>
    );
};
