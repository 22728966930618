// Import React and Fluent UI components
import React, { useEffect, useState } from "react";
import { MessageBar, MessageBarType } from "@fluentui/react";
import styles from "./NotificationBar.module.css";

// Define the TypeScript interface for the props
interface Message {
    message: string;
    messageBarType: MessageBarType;
}

interface NotificationBarProps {
    className?: string;
}

function createMessageFromMetadata(metadata: { type: string; message: string }): Message {
    // Map the metadata type to MessageBarType
    const typeMapping: { [key: string]: MessageBarType } = {
        info: MessageBarType.info,
        warning: MessageBarType.warning,
        error: MessageBarType.error,
        success: MessageBarType.success
        // Map other types as necessary
    };

    // Default to info if the type is not recognized
    const messageBarType: MessageBarType = typeMapping[metadata.type] || MessageBarType.info;

    return {
        message: metadata.message,
        messageBarType: messageBarType
    };
}

// Define the MessageGroup component
const NotificationBar: React.FC<NotificationBarProps> = ({ className }) => {
    const [messages, setMessages] = useState<Message[]>([]);

    useEffect(() => {
        const handleMessage = (event: any) => {
            setMessages((prevMessages: any) => [...prevMessages, createMessageFromMetadata(event.detail)]);
        };

        // Add event listener for custom websocket-message events
        window.addEventListener("notification-message", handleMessage);

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener("notification-message", handleMessage);
        };
    }, []);
    const onClose = (index: number) => {
        setMessages(messages.filter((m, i) => i !== index));
    };
    return (
        <div className={`${className} ${styles.messageContainer}`}>
            {messages.map((msg, index) => (
                <MessageBar key={index} messageBarType={msg.messageBarType} isMultiline={false} onDismiss={() => onClose(index)} dismissButtonAriaLabel="Close">
                    <div className={styles.messageContent}>{msg.message}</div>
                </MessageBar>
            ))}
        </div>
    );
};

export default NotificationBar;
